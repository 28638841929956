import React, { Component } from 'react'
import { StaticQuery, graphql, Link } from 'gatsby'
import { StyleSheet, css } from 'aphrodite'
import Scroll from 'react-scroll'
import Helmet from 'react-helmet'

import AltertineLayout from './layouts/AltertineLayout'

import Button from '@material-ui/core/Button'

import Classes from '../styles/classes'
import i18next from '../config/i18n/context'

import { withNamespaces } from 'react-i18next'

import Masonry, {ResponsiveMasonry} from "react-responsive-masonry"

const images = [
    "/assets/img/photos/1.jpg",
    "/assets/img/photos/2.jpg",
    "/assets/img/photos/3.jpg",
    "/assets/img/photos/4.jpg",
    "/assets/img/photos/5.jpg",
    "/assets/img/photos/6.jpg",
    "/assets/img/photos/7.jpg",
    "/assets/img/photos/8.jpg",
    "/assets/img/photos/9.jpg",
    "/assets/img/photos/10.jpg",
    "/assets/img/photos/11.jpg",
    "/assets/img/photos/12.jpg",
    "/assets/img/photos/13.jpg",
    "/assets/img/photos/14.jpg",
    "/assets/img/photos/15.jpg",
    "/assets/img/photos/16.jpg",
    "/assets/img/photos/17.jpg",
    "/assets/img/photos/18.jpg",
    "/assets/img/photos/19.jpg",
    "/assets/img/photos/20.jpg",
    
  ]

const styles = StyleSheet.create({
  footer: {
    backgroundColor: '#002337'
  },
  button: {
    display: 'block',
    margin: '1em 0'
  }
})

const IndexPage = ({ t }) => {
  const lang =
    i18next.language ||
    (typeof window !== 'undefined' && window.localStorage.i18nextLng)
  console.log('language home', lang)
  return (
    <AltertineLayout image="/assets/img/photos/1.jpg">
      <Helmet
        title={t('photos.meta.title')}
        meta={[
          {
            name: 'description',
            content:t('photos.meta.description')
          },
          { name: 'charset', content: 'UTF-8' }
        ]}
      />
      <section className="project_descr">
        <div className="container mt-100">
          <div className="row center">
            <div className="col-md-8 col-md-offset-2 col-sm-12">
              <div className="section-title">
                <h2 className="mb-50">{t('photos.title')}</h2>
                <ResponsiveMasonry columnsCountBreakPoints={{350: 1, 750: 2, 900: 3}}>
                    <Masonry columnsCount={3} gutter="10px">
                    {images.map((image, i) => (
                        <a href={image}>
                            <img
                            key={i}
                            src={image}
                            alt=""
                            style={{width: "100%", display: "block"}}
                            />
                        </a>
                    ))}
                    </Masonry>
                </ResponsiveMasonry>
              </div>
            </div>
          </div>
        </div>
      </section>
    </AltertineLayout>
  )
}

export default withNamespaces('home')(IndexPage)
